
.body{
    background-color: black;
    font-family: "Lilita One", sans-serif;
    font-weight: 400;
    font-style: normal;
}
.navbar{
    background-color: rgba(0, 0, 0, 0.4);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    border-radius: 10px;
}

#logo{
    width: 8%;
}
#navA{

}
.nav-item{
    font-size: 16px;
    color: #c0c0c0;
    padding: 1%;
}
.nav-item:hover{

    background-color: red;
    border-radius: 10px;
}
#navbar-nav{
    justify-content: end;
}
@media screen and (max-width: 1024px){
    #logo{
        width: 25%;
    }

   }
#navbarDropdownMenuLink{
    text-align: center;
}

