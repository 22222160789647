.footerdiv{
    flex-direction: row;
    width: 100%;
    border-top: 1px solid #c0c0c0;
    background-color: black;
}
.footerleft{
    display: flex;
    align-items: center;
    justify-content: start;
    width: 50%;
    margin-left: 5%;
}
.footerright{
    display: flex;
    align-items: center;
    justify-content: end;
    width: 100%;
    margin-right: 5%;
    flex-direction: column;

}
.footericon{
    color: #c0c0c0;
    margin: 3%;
    font-size: 30px;
}

.footertext{
    color: #c0c0c0;
    margin: 3%;
    font-size: 10px;
}
.footertext:hover{
    color: #FFFFFF;
}
.footericon:hover{
    color: #FFFFFF;
}
@media screen and (max-width: 1024px){
    .footerdiv{
        align-items: center;
        justify-content: center;
        flex-direction: column-reverse;
        text-align: center;
        height: 25vh;
        margin-top: 5%;
    }
    .footerleft{
        align-items: center;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        width: 100%;
    }
    .footerimg{
        width: 100%;
    }
    .footerleft{
        margin: 0;
    }
    .footerright{
        width: 0;
        display: none;
    }
}