.economicdiv{
    height: 100vh;
}
.economicimg{
    margin: 2%;
}
.economicimg6{
    width: 10%;
    position: relative;
    animation-name: example;
    animation-duration: 10s;
    animation-direction: reverse;
    animation-iteration-count: infinite;
}
@keyframes example {
    0%   {background-color:transparent; left:-10%; top:20%;}
    75%   {background-color:transparent; left:9%; top:-10%;}
    50%  {background-color:transparent; left:32%; top:20%;}
    25%  {background-color:transparent; left:50%; top:-20%;}
    10%  {background-color:transparent; left:65%; top:10%;}
    100% {background-color:transparent; left:-10%; top:20%;}
}
.economicimg6:hover{
    left: 20%;

}
@media screen and (max-width: 1023px){
    .economicdiv{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
    }
    .economicimg{
        width: 50%;
    }
    .economicimga{
        width: 100%;
    }
    .economicimg6{
        display: none;
        width: 0;
    }.economicimg5{
         margin-top: 0;
     }.economicimg4{
          margin-bottom: 0;
      }.economicimg3{
           margin-top: 0;
       }.economicimg2{
            margin-bottom: 0;
        }.economicimg1{
             margin-top: 0;
         }

    .economicimg{
        margin: 0;
    }
}
@media screen and (min-width: 1024px){

    .economicimg1{
        width: 18%;
        margin-top: 25%;
    }
    .economicimg2{
        width: 14%;
        margin-bottom: 23%;
    }.economicimg3{
         width: 13%;
         margin-top: 21%;
     }.economicimg4{
          width: 11%;
          margin-bottom: 26%;
      }.economicimg5{
           width: 11%;
           margin-top: 22%;
       }
    .economicimga{
        width: 100%;
        margin-top: 91%;
    }


}
