@import url('https://fonts.googleapis.com/css2?family=Lilita+One&display=swap');
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
  font-family: "Lilita One", sans-serif;
  font-weight: 400;
  font-style: normal;
}
code {
  font-family: "Lilita One", sans-serif;
  font-weight: 400;
  font-style: normal;
}
