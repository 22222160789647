.inrtobacgroud{
    background-image: url("../svg/background2.svg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 120vh;
}

.bleed-canvas {
    width: 100vw;
    height: 100%;
    position: absolute;
}

@media screen and (max-width: 1024px){
    .box--two{
        width: 100%;
    }
}
@media screen and (max-width: 1024px){
    #treeimg{
        width: 0;
        display: none;
    }
}
#treeimg{
    width: 20%;
}
.chopsticks {
    height: 20%;
    position: absolute;
    right: 0;
    top: 10%;
}

.veribear {
    width: 10vmin;
    position: absolute;
    bottom: 5%;
    right: 5%;

}

.bento {
    position: absolute;
    bottom: 0%;
    left: 10%;
    height: 40vmin;
}

@media (max-width: 768px) {
    .bento {
        height: 60vmin;
    }
}

.page1h1 {
    font-size: clamp(2rem, 6vw + 1rem, 8rem);
    font-weight: 600;
    background: linear-gradient(90deg, oklch(36% 0.50 340), oklch(90% 0.5 200));
    color: transparent;
    -webkit-background-clip: text;
}

section:nth-of-type(1) {
    height: 100vh;
    display: grid;
    place-items: center;
    background: transparent;
}



.sticky {
    width: 50%;
    aspect-ratio: 1;
    position: sticky;
    bottom: 0;
}

.sticky img {
    width: 150%;
    position: absolute;
    bottom: 0;
    translate: -5% 0;
}

section {
    container-type: inline-size;
}



.box {
    container-type: inline-size;
}

.box .logo {
    width: clamp(1rem, 4vw + 1rem, 4rem);
    aspect-ratio: 1;
    background: linear-gradient(90deg, purple, lightblue);
    justify-self: start;
    position: absolute;
    bottom: 100%;
    translate: 0 -25%;
    mask: url('data:image/svg+xml;utf8,<svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6"><path fill-rule="evenodd" d="M9 4.5a.75.75 0 01.721.544l.813 2.846a3.75 3.75 0 002.576 2.576l2.846.813a.75.75 0 010 1.442l-2.846.813a3.75 3.75 0 00-2.576 2.576l-.813 2.846a.75.75 0 01-1.442 0l-.813-2.846a3.75 3.75 0 00-2.576-2.576l-2.846-.813a.75.75 0 010-1.442l2.846-.813A3.75 3.75 0 007.466 7.89l.813-2.846A.75.75 0 019 4.5zM18 1.5a.75.75 0 01.728.568l.258 1.036c.236.94.97 1.674 1.91 1.91l1.036.258a.75.75 0 010 1.456l-1.036.258c-.94.236-1.674.97-1.91 1.91l-.258 1.036a.75.75 0 01-1.456 0l-.258-1.036a2.625 2.625 0 00-1.91-1.91l-1.036-.258a.75.75 0 010-1.456l1.036-.258a2.625 2.625 0 001.91-1.91l.258-1.036A.75.75 0 0118 1.5zM16.5 15a.75.75 0 01.712.513l.394 1.183c.15.447.5.799.948.948l1.183.395a.75.75 0 010 1.422l-1.183.395c-.447.15-.799.5-.948.948l-.395 1.183a.75.75 0 01-1.422 0l-.395-1.183a1.5 1.5 0 00-.948-.948l-1.183-.395a.75.75 0 010-1.422l1.183-.395c.447-.15.799-.5.948-.948l.395-1.183A.75.75 0 0116.5 15z" clip-rule="evenodd" /></svg>') center/contain no-repeat;

}

.box svg path {
    fill: blue;
}

.page1p {
    position: relative;
}



section:nth-of-type(4) {
    display: grid;
    padding-top: 2rem;
    gap: 4rem;
}

section:nth-of-type(4) .box:first-of-type {
    margin-bottom: 8rem;
}

/* Box styling */

.box {
    width: 70%;
    background-color: transparent;
    border-radius: 2rem;
    display: grid;
    grid-template-columns: 0.6fr 0.4fr;
    gap: 1rem;
    justify-content: end;
    padding: 1rem;
}
    @media screen and (max-width: 1024px){
    .box1{
        width: 100%;
        display: flex;
        margin-bottom: 25%;
        margin-top: -25%;
    }
}
.box__content {
    display: grid;
    place-items: center;
    grid-column: 2;
    min-height: 40cqi;
    padding: 2rem 0rem;
    align-content: center;
    jutify-items: start;
}

.box p {
    font-weight: 600;
    color: hsl(0 0% 50%);
    font-size: clamp(0.875rem, 0.5vw + 1rem, 2rem);
}

.box--two {
    background: radial-gradient(ellipse at bottom, #ff0000 0%, #0c0d13 100%);
    position: absolute;
    bottom: 0;
    z-index: -1;
}


.box--two p {
    font-size: clamp(1rem, 2vw + 1rem, 2.75rem);
    color: black;
    font-weight: 500;
}
.readyh2{
    font-size: 70px;
    font-weight: bold;
    background: linear-gradient(90deg, purple, lightblue);
    color: transparent;
    -webkit-background-clip: text;
}
.box--two p span {
    background: linear-gradient(90deg, purple, lightblue);
    color: transparent;
    -webkit-background-clip: text;
}
@media(max-width: 768px) {
    .box--two {
        width: 100%;
        padding: 2rem 1rem;
    }
    .box--two p {
        font-size: clamp(0.875rem, 0.5vw + 1rem, 2rem);
    }
}
@media(min-width: 768px) {
    .box {
        padding: 2rem;
    }

}

section:nth-of-type(4) {
    z-index: 2;
    position: relative;
}

@media screen and (min-width: 600px){
    section:nth-of-type(5) {
        display: flex;
        gap: 5rem;
        grid-template-columns: 1fr 1fr;
        padding: 4rem 0 20vh 0;
        transform-style: preserve-3d;
    }

    section:nth-of-type(5)::before {
        content: "";
        z-index: -1;
        background: red;
        position: absolute;
        bottom: 0;
        height: calc(100% + 60cqi);
        width: 100vw;
        left: 50%;
        translate: -50% 0;
        border-radius: 0 0 10vmin 10vmin;
        background: linear-gradient(hsl(333, 61%, 77%), hsl(221, 43%, 41%));
        mask: linear-gradient(transparent, white 25%);
    }
}


main {
    container-type: inline-size;
}

@container (max-width: 420px) {
    section:nth-of-type(5) {
        grid-auto-flow: row;
        grid-template-columns: 1fr;
    }
    section:nth-of-type(5)::before {
        height: calc(100% + 120cqi);
    }
}

@supports (animation-timeline: scroll()) {
    section {
        view-timeline-name: --section;
    }
    :is(.chopsticks, .bento) {
        animation: slide both linear;
        animation-timeline: --section;
        animation-range: exit 0 exit 25%;
    }
    .chopsticks {
        --x: 50%;
        --y: 100%;
        --opacity: 0;
    }
    .bento {
        --x: -100%;
        --y: 100%;
        --opacity: 0;
    }
    @keyframes slide {
        to {
            translate: var(--x, 0) var(--y, 0);
            opacity: var(--opacity, 1);
            scale: var(--scale, 1);
        }
    }

    section:nth-of-type(4) .box:nth-of-type(2) p {
        animation: slide both linear;
        animation-range: cover 15% cover 75%;
        animation-timeline: view();
        --opacity: 0;
        --y: -10%;
    }

    section:nth-of-type(5)::before {
        translate: -50% 10%;
        opacity: 0;
        animation: slide both linear;
        animation-range: entry 0 entry 25%;
        animation-timeline: --section;
        --opacity: 1;
        --y: 0;
        --x: -50%;
    }

    @keyframes slip {
        to {
            scale: 1;
            translate: -5% 0;
        }
    }

    .sticky img {
        scale: 1.5;
        translate: 30% 0;
        animation: slip both linear, slide both linear;
        animation-range: entry 50% entry 70%, exit 0% exit 15%;
        animation-timeline: --section;
        --y: 30%;
        --x: -5%;
    }

    section:nth-of-type(4) {
        padding-top: 8rem;
    }

    .sticky {
        clip-path: inset(-100% -100% 0 -100%);
    }

    .spacing-box {
        height: 35vh;
    }

    .veribear {
        animation: roll both linear;
        animation-timeline: --section;
        animation-range: exit;
    }

    @keyframes roll {
        to {
            rotate: 720deg;
            translate: 500% 0;
        }
    }
}
@media screen and(max-width: 1024px){
    #contactcard{
        display: none;
    }
    .pageicon{
    margin: 50%;
    }
}

.pageicon{
    font-size: 100px;
    margin: 5%;
    color: #FFFFFF;
}


.pageicon:hover{
    color: #858484;
    cursor: pointer;
}





