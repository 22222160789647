.introdiv{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;

}
#introh1{
    margin-top: 15%;
    margin-bottom: 15%;
    color: hsl(0, 2%, 55%);
    font-size: 50px;
}
@media screen and (max-width: 720px){
    #introh1{
        display: flex;
        flex-direction: column;
        margin-top: 30%;
        margin-bottom: 45%;
        margin-left: 3%;
        margin-right: 3%;
    }
    .span{
        font-size: 30px;
    }

}


#introbtn {
    border-radius: 4px;
    background-color: #383635;
    border: none;
    color: #FFFFFF;
    text-align: center;
    font-size: 20px;
    padding: 20px;
    width: 200px;
    transition: all 0.5s;
    cursor: pointer;
    margin: 5px;
}

#introbtn .btnspan {
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.5s;
}

#introbtn .btnspan:after {
    content: '\00bb';
    position: absolute;
    opacity: 0;
    top: 0;
    right: -20px;
    transition: 0.5s;
}

#introbtn:hover .btnspan {
    padding-right: 25px;
}

#introbtn:hover .btnspan:after {
    opacity: 1;
    right: 0;
}









.introtext{
    width: 50%;
    height: 100%;
    margin: 20%;
    }

*, *::before, *::after {
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    margin: 0 auto;
    background-color: rgba(0,0,0,0.4);
}
.h1 {
    display: inline-flex;
    position: relative;
    font-weight: 400;
    font-size: 7.8vw;
    line-height: 1.1;
    margin: 0;
    padding: 0;
    text-shadow: 0 0 60px rgba(0,0,0,0.2);
}
.h1::after {
    position: absolute;
    top: 0;
    bottom: -10px;
    left: 0;
    display: inline-block;
    overflow: hidden;
    width: 0%;
    white-space: pre;
    content: attr(data-text);
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke: 1px rgba(255,255,255,0.7);
    will-change: width;
    animation: grow 7s cubic-bezier(0.86,0,0.07,1) .4s infinite alternate both;
}
.span {
    display: inline-block;
    -webkit-text-stroke: 1px rgba(252, 252, 252, 0.62);
    -webkit-text-fill-color: transparent;
    margin: 0;
    padding: 0;
    position: relative;
}
.span::after {
    position: absolute;
    bottom: 0;
    left: 0;
    display: inline-block;
    overflow: hidden;
    width: 0%;
    white-space: pre;
    content: attr(data-text);

    will-change: width;
    animation: grow 7s cubic-bezier(0.86,0,0.07,1) 2.15s infinite alternate both;
    background: linear-gradient(to bottom,rgba(255,255,255,0.85) 0%, rgba(229, 191, 65, 0.65) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
@keyframes grow {
    0%, 10%, 90%, 100% {
        width: 0%;
    }
    50% {
        width: 100%;
    }
}



